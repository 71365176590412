import React from 'react'
import { connect } from 'react-redux'
import UserDetailsComponent from '../components/user-details/user'
import { withRouter } from 'react-router-dom'
import PaymentHistoryComponent from '../components/user-details/payment-history'
import DownloadHistoryComponent from '../components/user-details/download-history'
import AuditHistoryComponent from '../components/user-details/audit-history'
import CrateComponent from '../components/user-details/crate'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'
import UserFeaturesComponent from '../components/user-details/userFeatures'
import UserSecurityGroupsComponent from '../components/user-details/security-groups'
import * as actionCreators from '../actions/user'
import * as featureActionCreators from '../actions/feature'
import * as userActionCreators from '../actions/user'
import * as securityGroupActionCreators from '../actions/security_groups'
import { confirmationMessage, isProd, NOTIFICATIONS } from '../helpers/utils'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import _ from 'lodash'

class UserDetailsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 1,
      success: false,
      errorMessage: null,
      disabled: false,
      userImpersonationDuration: null,
    }

    this.reactivateAccount = this.reactivateAccount.bind(this)
    this.deactivateAccount = this.deactivateAccount.bind(this)
    this.impersonateUser = this.impersonateUser.bind(this)
    this.deactivateOnRenewal = this.deactivateOnRenewal.bind(this)
    this.deactivateImmediately = this.deactivateImmediately.bind(this)
    this.updateUserFeatures = this.updateUserFeatures.bind(this)
    this.handlerDisabled = this.handlerDisabled.bind(this)
  }

  componentDidMount() {
    this.props.loadUserDetails(this.props.match.params.id).then(() => {
      this.updateUserImpersonationDuration()
      if (this.state.userImpersonationDuration)
        this.interval = setInterval(() => {
          this.updateUserImpersonationDuration()
        }, 1000)
    })
    this.props.getUserFeatures(this.props.match.params.id)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  handlerDisabled() {
    this.setState({ disabled: !this.state.disabled })
  }

  createConfirmation = async (ref, title, subtitle, action) => {
    const response = await confirmationMessage(title, subtitle)
    if (response) {
      ref.setAttribute('disabled', 'disabled')
      this.handlerDisabled()
      ref.removeAttribute('disabled')
      this.handlerDisabled()
      action()
    }
  }

  reactivateAccount() {
    const { user } = this.props
    return this.createConfirmation(
      this.refs.btnRea,
      'Reactivate',
      'Are you sure you want to reactivate this user?',
      () => this.props.reactivateUser(user.user.userId)
    )
  }

  deactivateAccount() {
    const { user } = this.props
    return this.createConfirmation(
      this.refs.btnDea,
      'Deactivate',
      'Are you sure you want to deactivate this user?',
      () => this.props.deactivateUser(user.user.userId)
    )
  }

  updateUserImpersonationDuration() {
    const {
      user: {
        user: { userImpersonationValidity },
      },
    } = this.props

    let userImpersonationDuration = userImpersonationValidity
      ? moment.duration(moment.utc(userImpersonationValidity).diff(moment()))
      : null

    userImpersonationDuration =
      userImpersonationDuration > 0 ? userImpersonationDuration : null

    if (!userImpersonationDuration) {
      clearInterval(this.interval)
    }
    this.setState({ userImpersonationDuration })
  }

  impersonateUser() {
    const {
      user: {
        user: { userId },
      },
    } = this.props
    if (this.state.userImpersonationDuration) {
      return this.props
        .impersonateUser(userId, false)
        .then(() => this.updateUserImpersonationDuration())
    } else {
      this.createConfirmation(
        this.refs.btnImp,
        'USER IMPERSONATION',
        'Are you sure you want to activate USER IMPERSONATION for this user?',
        () => {
          return this.props.impersonateUser(userId, true).then(() => {
            this.interval = setInterval(() => {
              this.updateUserImpersonationDuration()
            }, 1000)
          })
        }
      )
    }
  }

  deactivateOnRenewal() {
    const { user } = this.props
    return this.createConfirmation(
      this.refs.btnRen,
      'Deactivate',
      'Are you sure you want to deactivate on renewal this user?',
      () => this.props.deactivateOnRenewal(user.user.userId)
    )
  }

  reactivateSubscription = () => {
    const { user } = this.props
    const isPaypalOrAuthorize = [
      'PAYPAL',
      'PAYPALUS',
      'PAYPALGB',
      'AUTHORIZE.NET',
    ].includes(user.user.gateway)
    let reactivationAction = () =>
      this.props.reactivateSubscription(user.user.userId)
    let title = 'Reactivate Subscription'
    let subtitleText =
      'Are you sure you want to reactivate on subscription pending cancellation?'
    // Deactivate after reactivating subscription if Paypal
    if (isPaypalOrAuthorize) {
      reactivationAction = () => {
        this.props
          .reactivateSubscription(user.user.userId)
          .then(() => this.props.deactivateUser(user.user.userId))
      }
      title = 'Cancel Pending Cancellation and Deactivate Immediately'
      subtitleText =
        'Are you sure you want to cancel pending cancellation and deactivate immediately?'
    }
    return this.createConfirmation(
      this.refs.btnRen,
      title,
      subtitleText,
      reactivationAction
    )
  }

  deactivateImmediately() {
    const { user } = this.props
    return this.createConfirmation(
      this.refs.btnInm,
      'Deactivate',
      'Are you sure you want to deactivate immediately this user?',
      () => this.props.deactivateImmediately(user.user.userId)
    )
  }

  updateUserFeatures(features) {
    const { user } = this.props
    this.setState({ success: false, errorMessage: null })
    this.props.updateUserFeatures(user.user.userId, features).then((err) => {
      if (!err) {
        this.setState({ success: true })
      } else {
        this.setState({
          errorMessage: _.get(err, 'error.message', JSON.stringify(err)),
        })
      }
    })
  }

  render() {
    const {
      user,
      feature: { features, loading },
    } = this.props
    const { tab, userImpersonationDuration } = this.state
    const { paymentProcessorFeatures } = this.props.user.user
    const { userInfo } = this.props.auth
    const { securityGroups } = userInfo || {}
    const isAdmin =
      securityGroups &&
      securityGroups.some((name) => name === 'admin' || name === 'super-admin')
    const activeSubscription = user.user.activeSubscription
    const pendingCancellation = user.user.cancellationIsScheduled
    const canCancel = user.user.activeSubscription && !pendingCancellation
    const isPaypal = ['PAYPAL', 'PAYPALUS', 'PAYPALGB'].includes(
      user.user.gateway
    )
    const pendingCancellationText = isPaypal
      ? 'DELETE PENDING CANCELLATION & DEACTIVATE'
      : 'DELETE PENDING CANCELLATION'
    const disableOnRenewalEnabled =
      activeSubscription &&
      paymentProcessorFeatures &&
      paymentProcessorFeatures.cancelOnRenewal
    const disableImmediatelyEnabled =
      canCancel && paymentProcessorFeatures.cancelImmediately
    return (
      <React.Fragment>
        {user.user && (
          <div>
            <h2>Customer Service</h2>
            <span className={'underline-bold'}>___</span>
            <div>
              <ul className="nav justify-content-center">
                <li className="nav-item">
                  <span
                    className={classNames('nav-link', { active: tab === 1 })}
                    onClick={() => {
                      this.setState({ tab: 1 })
                    }}
                  >
                    General Info
                  </span>
                </li>
                <li className="nav-item">
                  <span
                    className={classNames('nav-link', { active: tab === 2 })}
                    onClick={() => {
                      this.setState({ tab: 2 })
                    }}
                  >
                    Payment History
                  </span>
                </li>
                <li className="nav-item">
                  <span
                    className={classNames('nav-link', { active: tab === 3 })}
                    onClick={() => {
                      this.setState({ tab: 3 })
                    }}
                  >
                    Download History
                  </span>
                </li>
                <li className="nav-item">
                  <span
                    className={classNames('nav-link', { active: tab === 4 })}
                    onClick={() => {
                      this.setState({ tab: 4 })
                    }}
                  >
                    User Audit Log
                  </span>
                </li>
                <li className="nav-item">
                  <span
                    className={classNames('nav-link', { active: tab === 5 })}
                    onClick={() => {
                      this.setState({ tab: 5 })
                    }}
                  >
                    Features
                  </span>
                </li>
                <li className="nav-item">
                  <span
                    className={classNames('nav-link', { active: tab === 6 })}
                    onClick={() => {
                      this.setState({ tab: 6 })
                    }}
                  >
                    Crate
                  </span>
                </li>
                {isAdmin && (
                  <li className="nav-item">
                    <span
                      className={classNames('nav-link', { active: tab === 7 })}
                      onClick={() => {
                        this.setState({ tab: 7 })
                      }}
                    >
                      Security Groups
                    </span>
                  </li>
                )}
              </ul>
              <br />
            </div>
            <div className={classNames({ hide: tab !== 1 })}>
              <button
                ref="btnImp"
                onClick={this.impersonateUser}
                className={classNames(
                  'btn',
                  { 'btn-warning': userImpersonationDuration },
                  { 'btn-danger': !userImpersonationDuration }
                )}
              >
                {userImpersonationDuration
                  ? 'STOP USER IMPERSONATION'
                  : 'IMPERSONATE USER'}
              </button>
              {userImpersonationDuration ? (
                <span>
                  {' '}
                  WARNING: You are impersonating another user. The session will
                  autoexpire in {userImpersonationDuration.minutes()}:
                  {userImpersonationDuration.seconds()}
                </span>
              ) : (
                <span>
                  {' '}
                  WARNING: Use only if necessary. The password is{' '}
                  <strong>{this.props.user.user.email}+impersonate</strong>
                </span>
              )}

              <hr />
              <UserDetailsComponent
                updateUser={this.props.updateUserInfo}
                user={this.props.user}
              />
              <br />
              <div>
                <button
                  ref="btnRen"
                  onClick={
                    pendingCancellation
                      ? this.reactivateSubscription
                      : this.deactivateOnRenewal
                  }
                  className={classNames('btn', 'btn-danger', {
                    disabled: !disableOnRenewalEnabled,
                  })}
                >
                  {pendingCancellation
                    ? pendingCancellationText
                    : 'CANCEL & DEACTIVATE ON RENEWAL'}
                </button>{' '}
                {pendingCancellation ? (
                  <span className="badge badge-warning">
                    Scheduled Expiration Date:{' '}
                    {moment(user.user.cancellationScheduledDate).format(
                      'YYYY/MM/DD'
                    )}
                  </span>
                ) : (
                  ''
                )}
                <br />
                <br />
                <button
                  ref="btnInm"
                  onClick={this.deactivateImmediately}
                  className={classNames('btn', 'btn-danger', {
                    disabled: !disableImmediatelyEnabled,
                  })}
                >
                  CANCEL, REFUND & DEACTIVATE IMMEDIATELY
                </button>
                <br />
                <br />
                <>
                  <button
                    ref="btnDea"
                    onClick={this.deactivateAccount}
                    className={classNames('btn', 'btn-danger', {
                      disabled: !activeSubscription,
                    })}
                  >
                    DEACTIVATE ACCOUNT
                  </button>
                  <br />
                  <br />
                  <button
                    ref="btnRea"
                    onClick={this.reactivateAccount}
                    className={classNames('btn', 'btn-danger', {
                      disabled: activeSubscription,
                    })}
                  >
                    REACTIVATE ACCOUNT
                  </button>
                </>
              </div>
            </div>
            {!user.user.usedId && (
              <div>
                <div className={classNames({ hide: tab !== 2 })}>
                  <PaymentHistoryComponent
                    user={this.props.user}
                    canCancelSub={canCancel}
                  />
                </div>
                <div className={classNames({ hide: tab !== 3 })}>
                  <DownloadHistoryComponent user={this.props.user} />
                </div>
                <div className={classNames({ hide: tab !== 4 })}>
                  <AuditHistoryComponent user={this.props.user} />
                </div>
                <div className={classNames({ hide: tab !== 5 })}>
                  <UserFeaturesComponent
                    features={features}
                    loading={loading}
                    success={this.state.success}
                    errorMessage={this.state.errorMessage}
                    updateUserFeatures={this.updateUserFeatures}
                  />
                </div>
                <div className={classNames({ hide: tab !== 6 })}>
                  <CrateComponent user={this.props.user} />
                </div>
                {isAdmin && (
                  <div className={classNames({ hide: tab !== 7 })}>
                    <UserSecurityGroupsComponent
                      user={this.props.user}
                      securityGroups={this.props.securityGroups}
                      getSecurityGroupsAll={this.props.getSecurityGroupsAll}
                      getSecurityGroupsByUser={
                        this.props.getSecurityGroupsByUser
                      }
                      addUserToSecurityGroup={this.props.addUserToSecurityGroup}
                      removeUserFromSecurityGroup={
                        this.props.removeUserFromSecurityGroup
                      }
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <ToastContainer
          enableMultiContainer
          containerId={NOTIFICATIONS.USER_NOTIFICATIONS}
          autoClose={5000}
          position={toast.POSITION.BOTTOM_RIGHT}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    auth: state.auth,
    feature: state.feature,
    securityGroups: state.securityGroups,
  }
}

UserDetailsContainer.propTypes = {
  user: PropTypes.object,
}

export default withRouter(
  connect(mapStateToProps, {
    ...actionCreators,
    ...featureActionCreators,
    ...userActionCreators,
    ...securityGroupActionCreators,
  })(UserDetailsContainer)
)
